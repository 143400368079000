<template>
  <div class="order-change">
    <div class="header">
      <HiSteps :datas="stepList" :active="active" icon="success" :step="step" @changeStatus="changeActive" />
    </div>
    <div class="content">
      <LoanInfo v-show="active==1" :datas="form" @changeStatus="changeActive" />
      <BasicInfo v-show="active==2" :datas="form" @changeStatus="changeActive" />
      <AssetInfo v-show="active==3" :datas="form" @changeStatus="changeActive" />
      <CreditInfo v-show="active==4" :datas="form" @onSubmit="onSubmit" />
    </div>
  </div>
</template>

<script>
import HiSteps from '@/components/HiSteps'
import LoanInfo from './components/LoanInfo'
import BasicInfo from './components/BasicInfo'
import AssetInfo from './components/AssetInfo'
import CreditInfo from './components/CreditInfo'
import { addUserInfoAll } from '@/api/user'
import { getLocalUserInfo } from '@/utils/auth'
export default {
  components: { HiSteps, LoanInfo, BasicInfo, AssetInfo, CreditInfo },
  data() {
    return {
      active: 1,
      step: 1,
      stepList: [
        { id: 1, title: '贷款信息' },
        { id: 2, title: '基本信息' },
        { id: 3, title: '资产信息' },
        { id: 4, title: '征信信息' }
      ],
      form: {
        cityCode: '',
        loanTypeCode: '',
        loansAreUsedFor: '',
        unit: '万',
        fromType: '3',
        money: '',
        nickname: '',
        basicList: [],
        assetList: [],
        creditList: [],
        salesId: '',
        orderId: null //  生存orderId
      },
      isLock: false
    }
  },
  created() {
    const { orderId, salesmanId } = this.$route.query
    if (orderId) {
      this.form.orderId = orderId
    }
    if (salesmanId) {
      this.form.salesId = salesmanId
    }
    const { city } = getLocalUserInfo()
    console.log(city, '===========')
    this.form.cityCode = city.cityCode
  },
  methods: {
    changeActive(val) {
      if (val === 2) {
        if (!this.form.nickname) {
          return this.$toast('请输入姓名！')
        }
        if (this.form.money < 1 || this.form.money > 10000) {
          return this.$toast('贷款金额需在1-10000之间！')
        }
        if (!this.form.loanTypeCode) {
          return this.$toast('请选择贷款类型！')
        }
        if (!this.form.loansAreUsedFor) {
          return this.$toast('请输入贷款用途！')
        }
      }
      if (val === 3) {
        this.form.basicList.map(item => {
          if (this.isLock) return
          this.isSelected(item)
        })
      }
      if (val === 4) {
        this.form.assetList.map(item => {
          if (this.isLock) return
          this.isSelected(item)
        })
      }
      if (this.isLock) return
      this.active = val
      this.step = val
      document.title = this.stepList[this.active - 1].title
      console.log(this.form, '22')
    },
    isSelected(item) {
      if (item.children.length === 0) return // 是否有选项，没用则返回
      // 查找是否有选中项
      const selectItem = item.children.filter(it => {
        return it.selected
      })
      // 假如没用选中项则加锁
      if (selectItem.length === 0) {
        this.isLock = true
      }
      // 根据加锁状态处理对应事件
      if (this.isLock) {
        const options = {
          message: '请选择' + item.name,
          onClose: () => {
            this.isLock = false
          }

        }
        return this.$toast(options)
      } else {
        // 判断是否有下级，有的话将下级传递到函数中再次判断是否选中
        if (selectItem[0].children.length > 0) {
          return this.isSelected(selectItem[0].children[0])
        }
      }
    },
    async onSubmit() {
      console.log(this.form, 'form-------')
      const { firstIn, your } = this.$route.query
      this.form.creditList.map(item => {
        if (this.isLock) return
        this.isSelected(item)
      })
      if (this.isLock) return
      const sumbitLoad = this.$toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      })
      const basicList = this.form.basicList
      const assetList = this.form.assetList
      const creditList = this.form.creditList
      const listUserInfoAddByDictReq = [
        {
          sysDict: basicList,
          type: 1
        },
        {
          sysDict: creditList,
          type: 2
        },
        {
          sysDict: assetList,
          type: 3
        }
      ]
      delete this.form.basicList
      delete this.form.assetList
      delete this.form.creditList
      if (firstIn) {
        delete this.form.cityCode
        delete this.form.fromType
      }
      const { datas } = await addUserInfoAll({
        ...this.form,
        listUserInfoAddByDictReq
      })
      sumbitLoad.clear()
      if (this.form.salesId) {
        this.$store.dispatch('user/setSelectMsgStatus', true)
        this.$router.go(-1)
      } else {
        if (firstIn) {
          this.$router.replace({ name: 'chatRoom', params: { your }})
        } else {
          this.$router.replace({ name: 'managerMatch', params: { orderId: datas.orderId }})
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.order-change {
  display: flex;
  flex-flow: column;
  height: 100%;
  background: #fcfcfc;
  .header {
    position: relative;
    z-index: 10;
    padding-bottom: 30px;
  }
  .content {
    flex: 1;
    overflow: hidden;
    position: relative;
    z-index: 10;
  }
}
</style>
