<template>
  <div class="steps">
    <div v-for="(item,index) in datas" :key="item.id" class="item" :class="{active:step>index, selected: active == index+1}" @click="changeActive(index)">
      <div class="num" :style="{backgroundColor: step==index+1?activeColor:''}">
        <template v-if="icon && step > index+1">
          <van-icon :name="icon" />
        </template>
        <template v-else-if="activeIcon">
          <van-icon :name="activeIcon" />
        </template>
        <template v-else>
          {{ index+1 }}
        </template>
      </div>
      <div class="title" :class="{ 'icon-title': icon && step > index+1 }">{{ item.title }}</div>
      <div class="line" :style="{borderColor: step==index+1?activeLineColor:''}" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    datas: {
      type: Array,
      default: () => {}
    },
    active: {
      type: Number,
      default: 1
    },
    step: {
      type: Number,
      default: 1
    },
    icon: {
      type: String,
      default: ''
    },
    activeColor: {
      type: String,
      default: ''
    },
    activeLineColor: {
      type: String,
      default: ''
    },
    activeIcon: {
      type: String,
      default: ''
    }
  },
  methods: {
    changeActive(index) {
      // if (index + 1 > this.step) return
      this.$emit('changeStatus', index + 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  justify-content: space-between;
  padding: 25px 16px 0 16px;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    flex: 1;
    position: relative;
    .num {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1;
      width: 24px;
      height: 24px;
      line-height: 24px;
      color: #CCCCCC;
      border-radius: 100%;
      border: 2px solid #CCCCCC;
      background: #fcfcfc;
    }
    .title {
      padding-top: 18px;
      font-size: 12px;
      color: #999999;
    }
    .line {
      position: absolute;
      top: 17px;
      left: 50%;
      z-index: 0;
      width: 100%;
      border-bottom: 2px solid #cccccc;
    }
    &:last-child .line {
      display: none;
    }
    &.active {
      .num {
        color: #fff;
        background: #ff7a03;
        border: 2px solid #ff7a03;
      }
      .line {
        border-bottom: 2px solid #ff7a03;
      }
      .title {
        position: relative;
        top: -5px;
        color: #FF7700;
        &.icon-title {
          top: 0;
        }
      }
    }
    &.selected {
      position: relative;
      top: 0px;
      .line {
        top: 17px;
      }
      .num {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border: 4px solid #fdcfa7;
      }
    }
  }
}
</style>
