<template>
  <div class="dict-item">
    <div class="group">
      <div class="title" :style="{width: labelWidth}">{{ infos.name }}</div>
      <div class="list">
        <div v-for="item in infos.children" :key="item.id" class="item" :class="{ selected: item.selected }" @click="selectItem(item)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <template v-if="getSelectChildren">
      <DictItem v-for="item in getSelectChildren.children" :key="item.id" :infos="item" />
    </template>
  </div>
</template>

<script>
export default {
  name: 'DictItem',
  props: {
    infos: {
      type: Object,
      default: () => ({})
    },
    labelWidth: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // 获取
    getSelectChildren() {
      return this.infos.children.find(item => item.selected)
    }
  },
  methods: {
    // 选择item
    selectItem(item) {
      if (this.readOnly) return
      if (this.infos.code === item.category) {
        this.infos.children.map(i => {
          if (i.id === item.id) {
            i.selected = true
          } else {
            this.deselect(i)
          }
        })
      }
    },
    // 将该item下的selected（包括children）全部设为false
    deselect(item) {
      item.selected = false
      item.children.map(it => {
        it.selected = false
        if (it.children.length > 0) {
          this.deselect(it)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dict-item{
  .title {
    position: relative;
    padding: 18px 0;
    font-size: 14px;
    color: #333333;
    &::after {
      content: ' ';
      position: absolute;
      top: 18px;
      left: -20px;
      width: 3px;
      height: 12px;
      border-radius: 1px;
      background: #FF7700;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 28%;
      height: 30px;
      margin-right: 10px;
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 30px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid #F0F1F4;
      background: #F0F1F4;
      &.selected {
        color: #FE9E01;
        border: 1px solid #FE9A05;
        background: #fee0b4;
      }
    }
  }
}
</style>
