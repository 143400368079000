<template>
  <div class="loan-info">
    <div class="form">
      <van-form validate-first validate-trigger="onChange">
        <div class="item" style="padding: 0;">
          <div class="title">姓<span style="padding: 0 14px;" />名</div>
          <div class="input">
            <div class="border">
              <van-field v-model="datas.nickname" :readonly="userInfos.realName != ''" type="text" maxlength="16" placeholder="请输入贷款人的真实姓名" />
            </div>
          </div>
        </div>
        <div class="item" style="padding: 0;">
          <div class="title">贷款金额</div>
          <div class="input" style="display: flex; align-items: center;">
            <div class="border">
              <van-field v-model="datas.money" type="number" placeholder="请输入贷款金额" @input="checkInt" />
            </div>
            /万
          </div>
          <div v-if="isBeyond" style="padding-top: 5px; color: red; font-size: 14px;">贷款金额需在1-10000之间</div>
        </div>
        <div class="item">
          <div class="title">贷款类型</div>
          <div class="input type">
            <div v-for="it in creditList" :key="it.id" class="it" :class="it.code === datas.loanTypeCode?'selected':''" @click="selectCredit(it.code)">{{ it.title }}</div>
          </div>
        </div>
        <div class="item" style="align-items: flex-start;">
          <div class="title">贷款用途</div>
          <div class="input" style="width: 200px; border:1px solid #eee; padding: 5px; border-radius: 5px;">
            <van-field
              v-model="datas.loansAreUsedFor"
              rows="2"
              type="textarea"
              maxlength="50"
              placeholder="请输入"
              show-word-limit
            />
          </div>
        </div>
      </van-form>
    </div>
    <div class="bottom">
      <van-button
        round
        block
        type="info"
        color="linear-gradient(90deg, #FE9D02 0%, #F78017 100%)"
        style="margin-bottom: 20px;"
        @click="goNext"
      >
        下一步
      </van-button>
    </div>
  </div>
</template>

<script>
import { selectOrderById } from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      creditList: [
        // credit_mortgage:抵押,credit_loan:信贷
        { id: 1, title: '抵押贷', code: 'credit_mortgage' },
        { id: 2, title: '信用贷', code: 'credit_loan' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'userInfos'
    ]),
    isBeyond() {
      if (this.datas.money === '') return false
      if (this.datas.money < 1 || this.datas.money > 10000) {
        return true
      }
      return false
    }
  },
  async mounted() {
    const { orderId, lastOrderId } = this.$route.query
    if (orderId || lastOrderId) {
      await this.getUserInfo(orderId || lastOrderId)
    }
    // 假如已经实名默认为实名名字，且不能修改
    if (this.userInfos.realName) {
      this.datas.nickname = this.userInfos.realName
    }
  },
  methods: {
    checkInt(value) {
      this.datas.money = this.datas.money.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
      this.datas.money = this.datas.money.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
      this.datas.money = this.datas.money.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      this.datas.money = this.datas.money.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')// 只能输入两个小数
      if (this.datas.money.indexOf('.') < 0 && this.datas.money !== '') { // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        this.datas.money = parseFloat(this.datas.money)
      }
    },
    selectCredit(code) {
      this.datas.loanTypeCode = code
    },
    goNext() {
      if (!this.datas.nickname) {
        return this.$toast('请输入姓名！')
      }
      if (this.datas.money < 1 || this.datas.money > 10000) {
        return this.$toast('贷款金额需在1-10000之间！')
      }
      if (!this.datas.loanTypeCode) {
        return this.$toast('请选择贷款类型！')
      }
      if (!this.datas.loansAreUsedFor) {
        return this.$toast('请输入贷款用途！')
      }
      this.$emit('changeStatus', 2)
    },
    async getUserInfo(orderId) {
      const res = await selectOrderById({ orderId })
      const datas = res.datas || {}
      this.datas.money = datas.loan
      this.datas.loansAreUsedFor = datas.loansAreUsedFor
      this.datas.nickname = datas.appellation
      this.datas.loanTypeCode = datas.creditType === '8' ? 'credit_loan' : 'credit_mortgage'
    }
  }
}
</script>

<style lang="scss" scoped>
.loan-info {
  height: 100%;
  overflow-y: auto;
}
.form {
  margin: 0 16px;
  padding: 12px 16px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
  opacity: 1;
  border-radius: 12px;
  background: #fff;
  .item {
    display: flex;
    align-items: center;
    padding: 12px 0;
    background: #fff;
    .input {
      flex: 1;
      .border {
        padding: 10px 0;
        flex: 1;
        border-bottom: 1px solid #F3F3F5;
      }
      .van-cell {
        padding: 0;
      }
      .van-cell::v-deep {
        input::placeholder {
          color: #CCCCCC;
        }
      }
    }
    .icon {
      padding-right: 12px;
      .img {
        display: block;
        width: 32px;
        height: 32px;
      }
    }
    .title {
      width: 80px;
      font-size: 14px;
      font-weight: bold;
      color: #333;
    }
    .text{
      display: flex;
      align-items: center;
      color: #666666;
      line-height: 1;
      .icon {
        color: #a3a3a3;
        font-size: 16px;
      }
    }

    .type {
      display: flex;
      align-items: center;
      .it {
        padding: 8px 24px;
        margin-right: 10px;
        font-size: 12px;
        color: #666;
        border: 1px solid #F0F1F4;
        border-radius: 4px;
        background: #F0F1F4;
        &.selected {
          color: #FF7700;
          border: 1px solid #FF7700;
          background: #fff3e6;
        }
      }
    }
  }
  .notice {
    font-size: 12px;
    color: #E31717;
    text-align: center;
  }
}
.bottom {
  padding: 32px 16px 30px 16px;
}
</style>
